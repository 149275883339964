import React from 'react';
import { useState, useEffect } from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import PremiumFeature from 'sections/premium-feature';
import TableFeature from '../sections/table-feature';
import CalculatorFeature from '../sections/calculator-feature';
import InstructionFeature from '../sections/instruction-feature';
import GraduationFeature from '../sections/graduation-feature';
import PrintFeature from '../sections/print-feature';
import Pricing from 'sections/pricing';
import Signup from '../sections/pricingSignUp';
import Subscribe from '../sections/subscribe';
import Introduction from '../sections/introduction';
import MoreText from '../sections/more-text';

export default function IndexPage() {
  const [pricingPlan, setPricingPlan] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showHeaderButton, setShowHeaderButton] = useState(false);

  const handlePricingPlanChange = (plan) => {
    setPricingPlan(plan);
  };

  const contactFormHandler = (show) => {
    // if(show){
    //   setShowContactForm(true);
    // }
    setShowContactForm(!showContactForm);
  };

  useEffect(() => {
    document.addEventListener('scroll', _ => {
      var buttonElement = document.getElementById("signUpButton")
      if(buttonElement){
        let buttonElementY = buttonElement.getBoundingClientRect().y;
        if(buttonElementY <= 0){
          setShowHeaderButton(true)
        }else{
          setShowHeaderButton(false)
        }
      }
    })
    return () =>{
      setPricingPlan(null);
      setShowContactForm(false);
      setShowHeaderButton(false);
    }
  },[]);
    
  return (
    <Layout
      showHeader={true} 
      showMenu={true}
      showFooter={true}
      contactFormHandler={contactFormHandler}
      showButton={showHeaderButton}
    >
      <SEO
        title="MOU - Modelagem Orientada ao Usuário"
        description="MOU - Modelagem Orientada ao Usuário"
      />
      <Banner />
      {/* <PremiumFeature /> */}
      <Introduction />
      <TableFeature />
      <CalculatorFeature />
      <InstructionFeature />
      <GraduationFeature />
      <PrintFeature />
      <MoreText />
      {/* <AppFeature /> */}
      {/* <Pricing pricingHandle={handlePricingPlanChange}/> */}
      <Signup pricingPlan={pricingPlan} accountType={'individual'} promocode={'promo01'} />
      <Subscribe contactFormHandler={contactFormHandler} show={showContactForm}/>
      {/* <Testimonials /> */}
    </Layout>
  );
}
